import { createSlice } from '@reduxjs/toolkit';
import { CancellationSteps, UserSelection } from '@shared/types/cancellation';

interface InitialState {
  cancellationStep: CancellationSteps;
  userSelection: UserSelection | null;
}

const initialState: InitialState = {
  cancellationStep: CancellationSteps.STEP1,
  userSelection: {
    reason: null,
    reasonFeedback: undefined,
    keepSingleWash: false,
    expirationDate: undefined,
    marketingConsentChanged: false,
  },
};

const cancellationSlice = createSlice({
  name: 'cancellation',
  initialState,
  reducers: {
    setCancellationStep(state, { payload }) {
      state.cancellationStep = payload;
    },
    setUserSelection(state, { payload }) {
      state.userSelection = payload;
    },
  },
});

export const { setCancellationStep, setUserSelection } =
  cancellationSlice.actions;

export default cancellationSlice.reducer;
