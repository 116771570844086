import smoothscroll from 'smoothscroll-polyfill';
import { Provider as ReduxProvider } from 'react-redux';
import store from '@shared/store';
import i18nStringsContext from '@contexts/i18nStringsContext';
import { appWithTranslation } from 'next-i18next';
import { CookiesProvider } from 'react-cookie';
import Head from 'next/head';
import {
  ComponentType,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import 'intersection-observer';
import { useRouter } from 'next/router';
import SiteOptionsProvider from '@features/WordPress/components/SiteOptionsProvider';
import { GTM, isDevelopment } from '@utilities/environment';
import Script from 'next/script';
import '../shared/components/Adyen/AdyenDropin.css';
import '../shared/styles/app.css';
import DefaultLayout from '../shared/layouts/DefaultLayout';
import { AppProps as NextAppProps } from 'next/app';
import { DynamicDictionary, GeneralOptionsQuery } from '@shared/types';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { v4 as uuidv4 } from 'uuid';
import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';

type AppProps<P = any> = {
  pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

export type Page<P = {}> = NextPage<P> & {
  // You can disable whichever you don't need
  getLayout?: (page: ReactElement) => ReactNode;
  layout?: ComponentType;
};

type Props = NextAppProps<GeneralOptionsQuery> & {
  Component: Page;
};

const App = ({ Component, pageProps }: Props) => {
  const { page, generalOptions }: GeneralOptionsQuery = pageProps || {};
  const { locale } = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [statsigClient, setStatsigClient] = useState<StatsigClient | null>(
    null
  );

  if (typeof window !== 'undefined') {
    smoothscroll.polyfill();
  }

  let seo = {};

  if (page && page.seo) {
    seo = page.seo;
  }

  let options = null;

  if (generalOptions && generalOptions.options) {
    options = generalOptions.options;
  }

  const strings = options?.strings?.reduce<DynamicDictionary>((arr, cur) => {
    // no-param-reassign is a way to avoid us from inducing sideeffects outside of scope,
    // but the case of reduce is an exception to issue of sideeffects, since it is internally pure
    // eslint-disable-next-line no-param-reassign
    arr[cur.key] = cur.value;
    return arr;
  }, {});

  const hasBlackFadedHero =
    page?.sections?.sections[0]?.features?.includes('black_image_fade') ||
    false;

  useEffect(() => {
    const path = window.location.hash;

    if (path && path.includes('#')) {
      setTimeout(() => {
        const id = path.replace('#', '');
        const el = window.document.getElementById(id);

        if (!el) {
          return;
        }

        el.scrollIntoView({ behavior: 'smooth' });
      }, 1500);
    }
  }, []);

  const generalPageProps = {
    hasBlackFadedHero,
    template: page?.template?.id,
    ...options,
  };

  const getLayout =
    Component.getLayout ||
    ((content: ReactElement, props: any) => (
      <DefaultLayout {...props}>{content}</DefaultLayout>
    ));

  const NoSSRMasqueradeProvider = dynamic(
    () =>
      import(
        '@features/Auth/components/ProvideMasqueradeUser/ProvideMasqueradeUser'
      ),
    { ssr: false }
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let storedSessionId = sessionStorage.getItem('sessionId');

      if (!storedSessionId) {
        storedSessionId = uuidv4();
        sessionStorage.setItem('sessionId', storedSessionId);
      }

      const myStatsigClient = new StatsigClient(
        process.env.NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY as string,
        {
          userID: storedSessionId,
        },
        {
          environment: { tier: process.env.ENVIRONMENT },
        }
      );
      myStatsigClient
        .initializeAsync()
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
      setStatsigClient(myStatsigClient);
    }
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <ReduxProvider store={store}>
        <NoSSRMasqueradeProvider>
          <SiteOptionsProvider options={options}>
            <StatsigProvider client={statsigClient}>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
                />
              </Head>
              <CookiesProvider>
                <i18nStringsContext.Provider value={{ ...strings }}>
                  {getLayout(
                    <Component
                      {...pageProps}
                      {...page}
                      seo={seo}
                      locale={locale}
                    />,
                    generalPageProps
                  )}
                  {locale === 'de' && !isDevelopment && (
                    <Script
                      id="piwik-init"
                      strategy="afterInteractive"
                      type="text/javascript"
                      dangerouslySetInnerHTML={{
                        __html: `(function(window, document, dataLayerName, id) {
                      window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
                      function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
                      var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
                      var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
                      tags.async=!0,tags.src="https://washworld.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
                      !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
                      })(window, document, 'dataLayer', '265618cb-b17e-4bfd-8db2-b6262da5d94c');`,
                      }}
                    />
                  )}
                  {locale !== 'de' && (
                    <Script
                      id="CookieConsent"
                      src="https://policy.app.cookieinformation.com/uc.js"
                      type="text/javascript"
                      strategy="afterInteractive"
                    />
                  )}

                  <Script
                    id="google-tag-manager"
                    strategy="afterInteractive"
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${
                      GTM[locale!]
                    }');`,
                    }}
                  />
                </i18nStringsContext.Provider>
              </CookiesProvider>
            </StatsigProvider>
          </SiteOptionsProvider>
        </NoSSRMasqueradeProvider>
      </ReduxProvider>
    </>
  );
};

export default appWithTranslation<AppProps>(App);
